const TransferSource = {
  SUPPLIER: "SUPPLIER",
  CUSTOMER: "CUSTOMER",
  NONE: "NONE",
} as const;

const TransactionType = {
  TRUCKING_IN: "TRUCKING_IN",
  TRUCKING_OUT: "TRUCKING_OUT",
} as const;

export const TruckingCashInOutStatus = {
  CANCELLED: "CANCELLED",
  DRAFT: "DRAFT",
  SETTLED: "SETTLED",
  SUBMITTED: "SUBMITTED",
} as const;

export type EnumTruckingCashInOutStatus =
  (typeof TruckingCashInOutStatus)[keyof typeof TruckingCashInOutStatus];
export type EnumTruckingCashInOutTransactionType =
  (typeof TransactionType)[keyof typeof TransactionType];
export type EnumTruckingCashInOutTransferSource =
  (typeof TransferSource)[keyof typeof TransferSource];
