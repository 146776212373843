















































































































































































































































































































































































































































































































import {
  buildTruckingCashInOutFormLineState,
  buildTruckingCashInOutFormState,
  SearchBuilder,
} from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import {
  useBank,
  useBlob,
  useBranch,
  useCashInOut,
  useCoa,
  useContactData,
  useCurrency,
  useDisabledFromTomorrow,
  useRemoveRows,
  useTruckingPayment,
} from "@/hooks";
import { TruckingCashInOutMapper } from "@/mapper/TruckingCashInOut.mapper";
import { TruckingPaymentMapper } from "@/mapper/TruckingPayment.mapper";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { EnumPreferenceKey } from "@/models/enums/preference.enum";
import {
  EnumTruckingCashInOutStatus,
  EnumTruckingCashInOutTransferSource,
  TruckingCashInOutStatus,
} from "@/models/enums/TruckingCashInOut.enum";
import { DetailCashOut } from "@/models/interface/cashManagement.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { IPreferencesResponseDto } from "@/models/interface/preference";
import { ResponseListOfCurrency } from "@/models/interface/settings.interface";
import { TruckingPaymentResponseDto } from "@/models/interface/trucking-payment";
import { cashManagementServices } from "@/services/cashmanagement.service";
import { truckingCashInOutService } from "@/services/trucking-cash-in-out.service";
import { LabelInValue } from "@/types";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import {
  TruckingCashInOutCreateRequestDto,
  TruckingCashInOutFormLineState,
  TruckingCashInOutFormState,
} from "@interface/trucking-cash-in-out";
import { FormModel } from "ant-design-vue";
import { UploadFile } from "ant-design-vue/types/upload";
import { Decimal } from "decimal.js-light";
import moment from "moment";
import printJS from "print-js";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";

const ALLOWED_CANCEL_STATUS: EnumTruckingCashInOutStatus[] = [
  "DRAFT",
  "SUBMITTED",
];
const ALLOWED_UPDATE_STATUS: EnumTruckingCashInOutStatus[] = ["DRAFT"];
const ALLOWED_SUBMIT_STATUS: EnumTruckingCashInOutStatus[] = ["DRAFT"];

@Component({
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      getPreferenceByKey: "preferenceStore/GET_PREFERENCE_BY_KEY",
    }),
  },
  data() {
    this.onSearchAccount = debounceProcess(this.onSearchAccount, 500);
    return {};
  },
})
export default class TruckingCashInOutForm extends Mixins(MNotificationVue) {
  @Ref("CashForm") cashForm!: FormModel;

  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledFromTomorrow = useDisabledFromTomorrow;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  getPreferenceByKey!: (
    key: EnumPreferenceKey
  ) => IPreferencesResponseDto | undefined;

  formModel: TruckingCashInOutFormState = buildTruckingCashInOutFormState();

  formRules = {
    branch: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    type: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    transactionDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    currency: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    companyBank: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    cashInOutTransfer: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    description: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  loading = {
    update: false,
    submit: false,
    saveDraft: false,
    cancel: false,
    print: false,
    contact: false,
    type: false,
    currency: false,
    bank: false,
    branch: false,
  };

  disabled = {
    transferSource: false,
    branch: false,
    description: false,
    type: false,
    transactionDate: false,
    currency: false,
    rate: false,
    bankName: false,
    transferToFrom: false,
    contact: false,
    contactbank: false,
    chequeNumber: false,
    bankNameCheque: false,
    file: false,
    row: false,
  };

  show = {
    rates: false,
  };

  optTypes: Option[] = [];
  optCurrencies: Option[] = [];
  optCompanyBanks: Option[] = [];
  optContacts: Option[] = [];
  optContactBanks: Option[] = [];
  optBranch: Option[] = [];
  optTransferSource: EnumTruckingCashInOutTransferSource[] = [
    "SUPPLIER",
    "CUSTOMER",
    "NONE",
  ];
  optAccounts: Option[] = [];

  fileList: UploadFile[] = [];

  selectedRowKeys: string[] = [];
  columns = [
    {
      title: this.$t("lbl_account"),
      key: "account",
      scopedSlots: { customRender: "account" },
      width: "33%",
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      scopedSlots: { customRender: "amount" },
      width: "33%",
    },
    {
      title: this.$t("lbl_notes"),
      dataIndex: "notes",
      key: "notes",
      scopedSlots: { customRender: "notes" },
      width: "33%",
    },
  ];

  docId = "";
  paymentIds: string[] = [];

  get isModeCreate(): boolean {
    return this.$route.meta.mode === Mode.CREATE;
  }

  get isModeEdit(): boolean {
    return this.$route.meta.mode === Mode.EDIT;
  }

  get showContactField(): boolean {
    const source: EnumTruckingCashInOutTransferSource =
      this.formModel.cashInOutTransfer;
    return source === "CUSTOMER" || source === "SUPPLIER";
  }

  get allowSubmit(): boolean {
    return (
      ALLOWED_SUBMIT_STATUS.includes(this.formModel.status) ||
      this.allowSaveDraft
    );
  }

  get allowUpdate(): boolean {
    return ALLOWED_UPDATE_STATUS.includes(this.formModel.status);
  }

  get allowSaveDraft(): boolean {
    return !this.docId;
  }

  get allowCancel(): boolean {
    return ALLOWED_CANCEL_STATUS.includes(this.formModel.status);
  }

  get allowPrint(): boolean {
    return this.isSubmited;
  }

  get sumAmountLines(): number {
    const mapped = this.formModel.lines.map<number>(item => item.amount || 0);
    if (mapped.length) {
      return mapped.reduce((a, b) => {
        return new Decimal(b).plus(a).toNumber();
      });
    }

    return 0;
  }

  get contactLabel() {
    if (this.toCustomer) {
      return {
        contact: this.$t("lbl_customer_name").toString(),
        bank: this.$t("lbl_customer_bank").toString(),
      };
    } else {
      return {
        contact: this.$t("lbl_supplier_name").toString(),
        bank: this.$t("lbl_supplier_bank").toString(),
      };
    }
  }

  get toCustomer(): boolean {
    return this.formModel.cashInOutTransfer === "CUSTOMER";
  }

  get toSupplier(): boolean {
    return this.formModel.cashInOutTransfer === "SUPPLIER";
  }

  get isSubmited(): boolean {
    return this.formModel.status === "SUBMITTED";
  }

  get isPayment() {
    return this.$route.query.payment === "true";
  }

  async created(): Promise<void> {
    this.onSearchCurrency = debounceProcess(this.onSearchCurrency, 500);
    this.onSearchBranch = debounceProcess(this.onSearchBranch, 500);
    this.onSearchCompanyBank = debounceProcess(this.onSearchCompanyBank, 500);
    this.onSearchContact = debounceProcess(this.onSearchContact, 500);

    this.getBranchList();
    this.fetchTypes();
    this.fetchCurrencies(new RequestQueryParams());
    this.getAccountList();
    this.docId = this.$route.params.id;

    if (this.isModeCreate) {
      this.setDefaultCurrency();
      this.preFetchCompanyBanks({});

      this.setPaymentIds();
      if (this.isPayment && this.paymentIds && this.paymentIds.length > 0) {
        this.fetchPayments(this.paymentIds);
      }
    } else if (this.docId) {
      this.getDetailDoc(this.docId);
    }
  }

  setDefaultCurrency() {
    const preference: IPreferencesResponseDto | undefined =
      this.getPreferenceByKey("feature_base_currency");
    if (preference) {
      this.formModel.currency = {
        key: preference.value,
        label: preference.name,
      };
    }
  }

  getDetailDoc(id: string): void {
    cashManagementServices.detailCashOut(id).then(response => {
      this.setForm(response);
      this.appendAttachment(response);
      this.toggleCurrencyRates(response.currencyCode);
      this.setDisabledForm(response);
    });
  }

  setDisabledForm({ status }: DetailCashOut): void {
    if (status !== "DRAFT") {
      for (const key in this.disabled) {
        this.disabled[key] = true;
      }
    }
  }

  appendAttachment({ attachment }: DetailCashOut): void {
    if (!attachment) return;
    const { fileList } = this;
    this.fileList = [
      ...fileList,
      {
        uid: generateUUID(),
        name: attachment.split("/")[attachment.split("/").length - 1],
        status: "done",
        url: attachment,
        size: 0,
        type: "",
      },
    ];
  }

  setForm(detail: DetailCashOut): void {
    this.formModel = TruckingCashInOutMapper.toFormState(detail);
    this.preFetchContacts(this.formModel.cashInOutTransfer);
    this.preFetchCompanyBanks({});
    this.setContactBankOptions(detail.supplierOrCustomerId);
  }

  onSelectChange(selectedRowKeys: string[]): void {
    this.selectedRowKeys = selectedRowKeys;
  }

  async fetchTypes(): Promise<void> {
    const { toTypeOptions } = useCashInOut();
    const params = new RequestQueryParams();
    try {
      this.loading.type = true;
      const response = await truckingCashInOutService.getCashInOutListType(
        params
      );
      this.optTypes = toTypeOptions(response);
    } finally {
      this.loading.type = false;
    }
  }

  onSearchCurrency(search = ""): void {
    const searchBy = search
      ? `currencyCode~*${search}*_OR_description~*${search}*`
      : "";
    this.fetchCurrencies({ search: searchBy });
  }

  onChangeCurrency(value: LabelInValue): void {
    this.findCurrencyById(value.key);
    this.preFetchCompanyBanks(new RequestQueryParams());
  }

  findCurrencyById(id: string): void {
    if (!id) return;
    const { findById } = useCurrency();
    findById(id).then(response => {
      this.toggleCurrencyRates(response.currencyCode);
      this.setCurrencyRates(response.currencyCode);
    });
  }

  setCurrencyRates(currencyCode: string): void {
    this.fetchCurrencyRates(currencyCode).then(conversion => {
      const [curr] = conversion.data;
      this.formModel.rates = curr?.rate ?? 1;
    });
  }

  async fetchCurrencyRates(
    fromCurrency: string
  ): Promise<ResponseListOfCurrency> {
    const response = this.getPreferenceByKey("feature_base_currency");
    const baseCurrency = response?.name ?? "";
    const { findConversion } = useCurrency();
    const conversion = await findConversion(fromCurrency, baseCurrency);
    return conversion;
  }

  toggleCurrencyRates(currencyCode: string): void {
    this.show.rates = currencyCode !== "IDR";
  }

  async fetchCurrencies(params: RequestQueryParamsModel): Promise<void> {
    try {
      const { findAll, toOptions } = useCurrency();
      this.loading.currency = true;
      const response = await findAll(params);
      this.optCurrencies = toOptions(response);
    } finally {
      this.loading.currency = false;
    }
  }

  onSearchCompanyBank(search = ""): void {
    let searchBy = "";
    if (search) {
      searchBy = `bankAccName~*${search}*_OR_bankAccNumber~*${search}*_OR_bankName~*${search}*`;
    }
    this.preFetchCompanyBanks({ search: searchBy });
  }

  preFetchCompanyBanks(params: RequestQueryParamsModel): void {
    if (!this.formModel.currency) return;
    const defaultQuery = [`currency.secureId~${this.formModel.currency.key}`];
    if (params.search) defaultQuery.unshift(params.search);
    params.search = defaultQuery.join("_AND_");
    this.fetchCompanyBanks(params);
  }

  fetchCompanyBanks(params: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useBank();
    this.loading.bank = true;
    findAll(params)
      .then(response => {
        this.optCompanyBanks = toOptions(response.data);
      })
      .finally(() => {
        this.loading.bank = false;
      });
  }

  onChangeTransferToFrom(): void {
    const source: EnumTruckingCashInOutTransferSource =
      this.formModel.cashInOutTransfer;
    this.formModel.supplierOrCustomer = undefined;
    this.formModel.customerSupplierBank = undefined;
    this.formModel.transferOthers = "";
    if (source === "CUSTOMER" || source === "SUPPLIER") {
      this.preFetchContacts(source);
    }
  }

  onChangeContact(value: LabelInValue): void {
    this.formModel.customerSupplierBank = undefined;
    this.setContactBankOptions(value?.key);
  }

  /**
   * get available bank options from detail contact
   */
  setContactBankOptions(contactId: string): void {
    if (!contactId) return;
    const { toBankOptions, findById } = useContactData();
    findById(contactId).then(response => {
      this.optContactBanks = toBankOptions(response);
    });
  }

  onSearchContact(value = ""): void {
    const builder = new SearchBuilder();
    let q = "";
    if (value) {
      q = builder
        .push(["firstName", value], { like: "both" })
        .or()
        .push(["lastName", value], { like: "both" })
        .build();
    }
    if (this.toCustomer) {
      this.fetchCustomers({ search: q });
    } else if (this.toSupplier) {
      this.fetchSuppliers({ search: q });
    }
  }

  preFetchContacts(source: EnumTruckingCashInOutTransferSource): void {
    const params = new RequestQueryParams();
    if (source === "CUSTOMER") this.fetchCustomers(params);
    else this.fetchSuppliers(params);
  }

  fetchCustomers(params: RequestQueryParamsModel): void {
    const { findCustomers, toOptions } = useContactData();
    this.loading.contact = true;
    findCustomers(params)
      .then(response => {
        this.optContacts = toOptions(response.data);
      })
      .finally(() => {
        this.loading.contact = false;
      });
  }

  fetchSuppliers(params: RequestQueryParamsModel): void {
    const { findSuppliers, toOptions } = useContactData();
    this.loading.contact = true;
    findSuppliers(params)
      .then(response => {
        this.optContacts = toOptions(response.data);
      })
      .finally(() => {
        this.loading.contact = false;
      });
  }

  handleChange(info) {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.fileList = fileList;
    if (info.file.status === "done") {
      this.$notification.success({
        description: `${info.file.name} file uploaded successfully`,
        message: "Success",
        duration: 30,
      });
    } else if (info.file.status === "error") {
      this.$notification.error({
        description: `${info.file.name} file upload failed.`,
        message: "Error",
        duration: 30,
      });
    }
  }

  customRequest(options) {
    const data = new FormData();
    data.append("file", options.file);
    cashManagementServices
      .createUploadFile(data, "trucking")
      .then(response => {
        options.onSuccess(options);
        this.formModel.attachment = response.url;
      })
      .catch(err => {
        options.onError(err, options);
      });
  }

  beforeUpload(file) {
    const isLt5M = file.size;
    const exceeded = isLt5M >= 5242880;
    if (exceeded) {
      this.$notification.error({
        description: "File must smaller than 5MB!",
        message: "Error",
        duration: 30,
      });
      return false;
    }
    return true;
  }

  addRow(): void {
    this.formModel.lines.push(buildTruckingCashInOutFormLineState());
  }

  confirmDelete(): void {
    if (this.selectedRowKeys.length > 0) {
      this.showConfirmationDeleteItems(this.deleteRow);
    } else {
      this.showNotifError("lbl_modal_delete_error_description");
    }
  }

  deleteRow(): void {
    const { newSource, deletedRows } = useRemoveRows(
      this.formModel.lines,
      this.selectedRowKeys
    );
    this.selectedRowKeys = [];
    this.formModel.lines = [...newSource];
    deletedRows.forEach(item => {
      if (item.lineId) this.formModel.cashInOutLineDelete.push(item.lineId);
    });
    this.updateTotalAmount();
  }

  onChangeAmount(): void {
    this.updateTotalAmount();
  }

  updateTotalAmount(): void {
    this.formModel.totalAmount = this.sumAmountLines;
  }

  async printDetail(id: string) {
    const { toObjectUrl } = useBlob();
    this.loading.print = true;
    const response = await truckingCashInOutService.printDetail(id);
    printJS(toObjectUrl(response));
    this.loading.print = false;
  }

  async printSummary(id: string) {
    const { toObjectUrl } = useBlob();
    this.loading.print = true;
    const response = await truckingCashInOutService.printSummary(id);
    printJS(toObjectUrl(response));
    this.loading.print = false;
  }

  handlePrint({ key }: { key: "detail" | "summary" }): void {
    if (key === "detail") {
      this.printDetail(this.docId);
    } else {
      this.printSummary(this.docId);
    }
  }

  validateForm(type: "submit" | "update"): void {
    this.cashForm.validate((valid: boolean) => {
      if (!valid) return;
      else if (type === "update") this.handleUpdate();
      else if (type === "submit" && this.docId) this.handleUpdate(true);
      else if (type === "submit") this.handleSubmit();
    });
  }

  handleUpdate(shouldSubmit = false): void {
    const payload = TruckingCashInOutMapper.toCreateDto(this.formModel);
    if (shouldSubmit) payload.status = TruckingCashInOutStatus.SUBMITTED;
    this.updateCashInOut(this.docId, payload);
  }

  handleSubmit(): void {
    const payload = TruckingCashInOutMapper.toCreateDto(this.formModel);
    payload.status = TruckingCashInOutStatus.SUBMITTED;
    this.postCashInOut(payload, "submit");
  }

  handleBack(): void {
    const fromPayment = Boolean(this.$route.query.payment);
    if (fromPayment) {
      this.$router.push({
        name: "trucking.payment",
      });
    } else {
      this.$router.push({
        name: "trucking.cash-in-out.list",
      });
    }
  }

  handleCancel(): void {
    this.loading.cancel = true;
    cashManagementServices
      .cancelCashOut(this.docId)
      .then(item => {
        this.handleBack();
        this.listDeletedId = [];
        this.showNotifSuccess("notif_cancel_success", {
          documentNumber: item.documentNo,
        });
      })
      .finally(() => {
        this.loading.cancel = false;
      });
  }

  async handleSaveDraft(): Promise<void> {
    try {
      await this.cashForm.validate();
      this.loading.saveDraft = true;
      const payload = TruckingCashInOutMapper.toCreateDto(this.formModel);
      payload.status = TruckingCashInOutStatus.DRAFT;
      this.postCashInOut(payload, "saveDraft");
    } catch {
      this.showNotifWarning("notif_validation_error");
    } finally {
      this.loading.saveDraft = false;
    }
  }

  postCashInOut(
    payload: TruckingCashInOutCreateRequestDto,
    loader: "saveDraft" | "submit"
  ): void {
    this.loading[loader] = true;
    truckingCashInOutService
      .create(payload)
      .then(response => {
        if (payload.status === "DRAFT") {
          this.$router.replace({
            name: "trucking.cash-in-out.edit",
            params: { id: response.id },
            query: this.$route.query,
          });
        } else {
          this.$router.replace({
            name: "trucking.cash-in-out.detail",
            params: { id: response.id },
            query: this.$route.query,
          });
        }
        this.showNotifSuccess("notif_create_success", {
          documentNumber: response.documentNo || "",
        });
      })
      .finally(() => (this.loading[loader] = false));
  }

  updateCashInOut(
    id: string,
    payload: TruckingCashInOutCreateRequestDto
  ): void {
    this.loading.update = true;
    this.loading.submit = true;
    truckingCashInOutService
      .update(id, payload)
      .then(item => {
        this.$router.push({ name: "trucking.cash-in-out.list" });
        const label =
          payload.status === "SUBMITTED"
            ? "notif_submit_success"
            : "notif_update_success";
        this.showNotifSuccess(label, {
          documentNumber: item.documentNo || "",
        });
      })
      .finally(() => {
        this.loading.update = false;
        this.loading.submit = false;
      });
  }

  async getBranchList(params?: RequestQueryParamsModel) {
    const { findAll, toOptions } = useBranch();
    try {
      this.loading.branch = true;
      const response = await findAll(params);
      this.optBranch = toOptions(response.data);
    } finally {
      this.loading.branch = false;
    }
  }

  onSearchBranch(value?: string) {
    const { searchBy } = useBranch();
    const params = new RequestQueryParams();
    if (value) {
      params.search = searchBy({ name: value });
    }
    this.getBranchList(params);
  }

  async getAccountList(params?: RequestQueryParamsModel) {
    const { findAllChildAccount, toOptions } = useCoa();
    const response = await findAllChildAccount(params);
    this.optAccounts = toOptions(response.data);
  }

  async onSearchAccount(record: TruckingCashInOutFormLineState, val?: string) {
    const { findAllChildAccount, toOptions, filterBy } = useCoa();
    record.isSearching = true;
    try {
      record.isAccountLoading = true;
      const params = new RequestQueryParams();
      if (val) {
        params.search = filterBy({ code: val, description: val });
      }
      const response = await findAllChildAccount(params);
      record.accountOptions = toOptions(response.data);
    } finally {
      record.isAccountLoading = false;
    }
  }

  async fetchPayments(ids: string[]): Promise<void> {
    const { findPaymentListByIds } = useTruckingPayment();
    const response: TruckingPaymentResponseDto[] = await findPaymentListByIds(
      ids
    );
    const paymentDate: string = this.$route.query.paymentDate as string;
    this.formModel = TruckingPaymentMapper.toTruckingCashInOutFormState(
      response,
      paymentDate ? moment(paymentDate) : undefined
    );
    this.updateTotalAmount();
  }

  setPaymentIds(): void {
    if (
      this.$route.query.ids !== null &&
      Array.isArray(this.$route.query.ids)
    ) {
      for (let item of this.$route.query.ids) {
        if (item) {
          this.paymentIds.push(item);
        }
      }
    } else if (this.$route.query.ids !== null) {
      this.paymentIds.push(this.$route.query.ids);
    }
  }
}
