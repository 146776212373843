import { buildCashInOutLineCreateDto } from "@/builder/cash-in-out/CashInOutDtoBuilder";
import { buildTruckingCashInOutCreateDto } from "@/builder/trucking-cash-in-out/TruckingCashInOutDtoBuilder";
import {
  buildTruckingCashInOutFormLineState,
  buildTruckingCashInOutFormState,
} from "@/builder/trucking-cash-in-out/TruckingCashInOutStateBuilder";
import {
  EnumTruckingCashInOutStatus,
  EnumTruckingCashInOutTransactionType,
  EnumTruckingCashInOutTransferSource,
} from "@/models/enums/TruckingCashInOut.enum";
import { CashInOutLineCreateRequestDto } from "@/models/interface/cash-in-out";
import {
  CashInOutLineDetail,
  DetailCashOut,
} from "@/models/interface/cashManagement.interface";
import {
  TruckingCashInOutCreateRequestDto,
  TruckingCashInOutFormLineState,
  TruckingCashInOutFormState,
} from "@interface/trucking-cash-in-out";
import moment from "moment";

export class TruckingCashInOutMapper {
  static toFormState(data: DetailCashOut): TruckingCashInOutFormState {
    const state: TruckingCashInOutFormState = buildTruckingCashInOutFormState();
    state.journalId = data.journalId;
    state.journalNumber = data.journalNo;
    state.status = data.status as EnumTruckingCashInOutStatus;
    state.transferOthers = data.transferSource;
    state.documentNo = data.documentNo;
    state.branch = { key: data.branchId, label: data.branchName };
    state.type = data.type as EnumTruckingCashInOutTransactionType;
    state.transactionDate = moment(data.transactionDate);
    state.currency = { key: data.currencyId, label: data.currencyCode };
    state.rates = data.rates;
    state.companyBank = {
      key: data.companyBankId,
      label: data.companyBankName,
    };
    state.cashInOutTransfer =
      data.cashInOutTransfer as EnumTruckingCashInOutTransferSource;
    state.customerSupplierBank = {
      key: data.customerSupplierBank,
      label: data.customerSupplierBank,
    };
    state.referenceNo = data.referenceNo;
    state.supplierOrCustomer = {
      key: data.supplierOrCustomerId,
      label: data.supplierOrCustomerName,
    };
    state.chequeNo = data.chequeNo;
    state.chequeBankName = data.chequeBankName;
    state.description = data.description;
    state.attachment = data.attachment;
    state.lines = data.cashInOutLines.map(
      TruckingCashInOutMapper.toFormLineState
    );
    state.totalAmount = data.totalAmount;
    return state;
  }

  static toFormLineState(
    data: CashInOutLineDetail
  ): TruckingCashInOutFormLineState {
    const state: TruckingCashInOutFormLineState =
      buildTruckingCashInOutFormLineState();

    state.account = {
      key: data.accountId,
      label: `${data.accountCode} - ${data.accountDescription}`,
    };
    state.amount = data.amount;
    state.notes = data.notes;
    state.lineId = data.id;

    return state;
  }

  static toCreateLineDto(
    state: TruckingCashInOutFormLineState
  ): CashInOutLineCreateRequestDto {
    const dto: CashInOutLineCreateRequestDto = buildCashInOutLineCreateDto();

    dto.accountId = state.account?.key ?? "";
    dto.amount = state.amount;
    dto.lineId = state.lineId;
    dto.notes = state.notes;
    dto.truckingPaymentId = state.truckingPaymentId;

    return dto;
  }

  static toCreateDto(
    state: TruckingCashInOutFormState
  ): TruckingCashInOutCreateRequestDto {
    const dto: TruckingCashInOutCreateRequestDto =
      buildTruckingCashInOutCreateDto();

    dto.branchId = state.branch?.key ?? "";
    dto.transactionDate = state.transactionDate?.format() ?? "";
    dto.currencyId = state.currency?.key ?? "";
    dto.rates = state.rates;
    dto.companyBankId = state.companyBank?.key ?? "";
    dto.cashInOutTransfer = state.cashInOutTransfer;
    dto.supplierOrCustomerId = state.supplierOrCustomer?.key ?? "";
    dto.chequeNo = state.chequeNo;
    dto.chequeBankName = state.chequeBankName;
    dto.description = state.description;
    dto.attachment = state.attachment;
    dto.journalNumber = state.journalNumber;
    dto.status = state.status;
    dto.totalAmount = state.totalAmount;
    dto.customerSupplierBank = state.customerSupplierBank?.key ?? "";
    dto.referenceNo = state.referenceNo;
    dto.cashInOutLineDelete = state.cashInOutLineDelete;
    dto.cashInOutLineDTOS = state.lines.map(
      TruckingCashInOutMapper.toCreateLineDto
    );
    dto.transferSource = state.transferOthers;
    dto.type = state.type;

    return dto;
  }
}
